@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductDiscountLabel {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    width: auto;
    min-height: 35px;
    border: solid 1px $error;
    color: $error;
    font-weight: 600;
    font-size: 14px;
    background: $white;
}
