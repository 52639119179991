@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Breadcrumb {
    &-Link {
        color: $color-primary;
        text-decoration: underline;
    }

    &:last-child {
        .Breadcrumb-Link {
            color: $black;
            text-decoration: none;
        }
    }

    .ChevronIcon {
        height: 14px;
        width: 14px;
        fill: $gray-light;
    }

    &-Name {
        svg {
            margin-block-end: -3px;
        }
    }
}
