@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.Countdown {
    &-Finished {
        border: 2px solid $black;
        font-size: 28px;
        height: 60px;
        padding: 2px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-Content {
        display: flex;
        margin-block-end: 16px;
        margin-inline: -3px;
    }

    &-Item {
        border: 2px solid $black;
        width: 60px;
        height: 60px;
        margin: 0 3px;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }

    &-Label {
        display: block;
        font-size: 10px;
        background: $black;
        color: $white;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        padding: 0 0 2px;
    }

    &-Number {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
