@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SomethingWentWrong {
    background: $white;
    padding: 40px 0;
    text-align: center;

    @include flex($align: center, $justify: center, $dir: column);

    &-Heading,
    &-SubHeading {
        font-size: $fs-m;
        margin-block-end: 0;
    }

    &-Heading {
        margin-block-start: 32px;

        @include mobile {
            line-height: normal;
            margin-block-end: 16px;
        }
    }

    &-Button {
        margin-block-start: 32px;
    }
}
