@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewRating {
    .StarIcon {
        margin-inline-end: 6px;
    }

    &-Counter {
        font-size: $fs-xs;
        font-weight: $fw-regular;
    }
}
