@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SliderProgressBar {
    height: 2px;
    background-color: $gray-light;
    width: 100%;
    margin-block-start: 24px;

    &-Filler {
        position: absolute;
        inset-inline-start: 0;
        transform: scaleX(0);
        transform-origin: left;
        background-color: $scrollbar-thumb-color;
        height: 100%;
        width: 100%;
        display: block;
        transition: transform .3s ease-in-out;
    }
}

@include desktop {
    .SliderProgressBar {
        &-Filler {
            background-color: $color-primary;
        }
    }
}
