@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCompareButton {
    .ProductCompareButton {
        &-Button.Button {    
            &:hover,
            &:focus {
                background-color: unset;
                height: var(--compare-button-size);
                padding: 0;
            }
        }
    }
}
