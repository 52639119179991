a, button {
    &.Link {
        border-block-end: 1px solid $color-primary;
        color: $color-primary;
        padding-block-end: 4px;

        &:hover,
        &:focus {
            color: $color-primary-darker;
            border-color: $color-primary-darker;
        }
    }
}

@include mobile {
    a {
        &.Link {
            padding-block-end: 2px;
        }
    }
}
