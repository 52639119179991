.CmsBlock {
    &-Wrapper {
        &_type {
            &_menu_right_banner {
                width: 215px;
                margin-block-end: 32px;
            }
        }
    }
}
