@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-recent-posts-border: 1px solid lightgray;
}

.BlogRecentPosts {
    &-PostCard {
        display: flex;
        padding: 10px 0;
    }

    &-Title {
        font-size: 24px;
    }

    &-PostTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-block-start: 0;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: block;
        height: 48px;
        display: -webkit-box;
    }

    &-PostTitlePlaceholder {
        &::after {
            content: 'aaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-ImageWrapper {
        height: 72px;
        width: 72px;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-Wrapper {
        display: block;
        margin-block-start: 30px;
        margin-block-end: 15px;
        padding-block-start: 15px;
        flex-basis: 60%;
        border-top: var(--blog-recent-posts-border);
    }

    &-CardWrapper {
        width: calc(100% - 70px);
        padding-inline-start: 10px;
    }

    &-Date {
        position: absolute;
        inset-block-end: 0;
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }

    &-LoadMoreWrapper {
        padding-block-start: 40px;
        text-align: center;

        @include mobile {
            order: 2;
            width: 100%;
        }

        flex-basis: 100%;
    }

    & &-LoadMoreButton {
        width: 158px;
        height: 56px;
        margin: auto;
        color: var(--load-more-button-color);
        background-color: var(--load-more-button-background);
        border: none;
        border-radius: 3px;

        @include button;
    }

    &-ReadMore {
        svg, path {
            fill: $default-primary-base-color;
        }
    }
}
