@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.CategoryAdditionalDescription {
    margin-bottom: 30px;

    h1, h2, h3, h4, h5, h6 {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 27px;
        font-weight: 400;
        color: $black;
    }

    &, p {
        font-size: 14px;
        line-height: 21px;
        color: $black;
    }

    strong {
        color: $black;
        font-weight: 400;
    }
}
