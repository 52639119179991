@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductLabel {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    background-color: var(--label-background-color);
    font-size: $fs-xxs;
    font-weight: $fw-semi-bold;
    color: var(--label-text-color);
    z-index: 4;

    @include flex($dir: column, $align: flex-start, $justify: center);

    &-Text {
        padding: 0 8px;
        line-height: 23px;
        height: 23px;
        margin: 0 0 10px;

        @include mobile {
            font-size: $fs-xxxs;
        }
    }

    &-Image {
        margin: 0 0 10px;
    }

    @include mobile {
        margin: 10px 2px;
    }
}
