/* stylelint-disable declaration-no-important */

.ceneo-widget-responsive {
    inset-inline-start: 0 !important;
    inset-inline-end: unset !important;
    inset-block-end: 0 !important;

    @include desktop {
        inset-inline-start: 16px !important;
    }
}
