@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/mixins';

.PromotionalBanner {
    color: $color-primary;

    &-Text {
        word-break: break-word;
        color: currentColor;
    }

    &.tile {
        @include mobile {
            justify-content: flex-end;

            img {
                display: none;
            }

            .PromotionalBanner {
                &-Button {
                    inset-inline-start: 16px;
                    margin-block: 16px;
                }

                &-Text {
                    inset-block-start: 0;
                    inset-inline-start: 16px;
                    padding-block: 16px;
                }
            }
        }
    }

    &.header {
        @include mobile {
            padding-inline: 0;
            padding-block: 0;
            border-color: transparent;
            margin-block-end: 0;

            .PromotionalBanner {
                &-Text {
                    padding-inline: 18px;
                    padding-block: 24px;
                }
            }
        }
    }

    &-Wrapper {
        &::before {
            display: none;
        }
    }
}
