@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ExpandableContent {
    &-Icon {
        font-size: $fs-xl;
        color: $color-primary;
    }

    &-Heading {
        font-size: $fs-m;
        font-weight: $fw-semi-bold;

        @include mobile {
            font-size: $fs-s;
        }
    }

    .ProductAttributeValue {
        &-Text {
            label:first-of-type {
                .input-control {
                    border: 1px solid #DDDDDD;
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                }
            }

            span {
                font-size: $fs-s;
                display: inline-block;
            }
        }

        &-SubLabel {
            font-size: $fs-s;
            font-weight: normal;
        }
    }

    .ChevronIcon {
        fill: $color-primary;
    }

    &_expandableDesktop {
        .ExpandableContent-Heading {
            cursor: pointer;
        }

        .ExpandableContent-Content {
            transition-property: opacity;
            transition-duration: 200ms;
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &_isContentExpanded {
                opacity: 1;
                max-height: 100%;
            }
        }
    }
}
