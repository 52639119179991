@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/mixins';

.Slider {
    margin-block-end: 0;

    @include mobile {
        padding-block-end: 14px;
    }

    .Slider {
        &-Container {
            @include flex(wrap, row, center, center)
        }

        &-Content {
            position: absolute;
            width: 100%;
            padding: 24px 16px;
            z-index: 2;

            @include tabletAndAbove {
                padding: 64px 32px;
                inset-inline-start: 0;
                inset-block-end: 0;
                max-width: 480px;
                width: 40%;
            }

            h1 {
                font-size: $fs-xxxxl;
                line-height: 1.1;

                @include mobile {
                    font-size: 40px;
                }

                @include tablet {
                    font-size: $fs-xxxl;
                }
            }

            @include mobile {
                inset-block-start: 0;

                h3 {
                    font-size: $fs-xl;
                    margin-block-end: 18px;
                }

                &~.Slider-Image {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        inset-inline: 0;
                        inset-block-start: 0;
                        z-index: 1;
                        height: 100px;
                        background: linear-gradient(0deg, transparent 0%, $white 100%);
                    }
                }
            }
        }

        &-Image {
            width: 100%;

            @include aspect-ratio(61.5%, "> picture");

            @include desktop {
                @include aspect-ratio(32.6%, "> picture");
            }
        }

        &-Button {
            --button-padding: 39px;
            --button-hover-padding: 39px;

            font-size: $fs-s;
            margin-block-start: 25px;

            @include tablet {
                --button-padding: 15px;
                --button-hover-padding: 15px;

                font-size: $fs-xs;
                margin-block-start: 10px;
            }

            @include mobile {
                display: none;
            }
        }

        &-Page {
            width: auto;
            cursor: pointer;
            font-size: $fs-xs;
            color: $gray;
            font-weight: $fw-light;
            margin-block-start: 25px;
            min-height: 39px;
            white-space: pre-wrap;
            margin-block-end: 6px;
            padding: 0 10px;
            border: 1px solid transparent;

            @include trans(color);
            @include flex($align: center, $justify: center);

            &:hover {
                color: $color-primary;
            }
        }
    }

    .slick-active {
        .Slider-Page {
            border: solid 1px $gray-light;
            padding: 0 10px;
            color: $black;
            font-weight: $fw-regular;
        }
    }

    .slick-dots {
        position: static;
        height: auto;
        width: auto;
        overflow-x: auto;

        @include flex($align: center, $justify: space-between);

        @include mobile {
            @include flex($justify: flex-start);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-primary;

            @include tabletAndAbove {
                height: 4px;
            }
        }

        &::-webkit-scrollbar {
            @include tabletAndAbove {
                height: 4px;
            }
        }

        li {
            height: auto;
            width: auto;
            margin-block-end: 0;
            text-align: center;

            @include mobile {
                min-width: 150px;
            }

            @include tabletAndAbove {
                margin: 0 0 16px;
            }

            &::after {
                display: none;
            }

            >div {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                height: 46px;
                line-height: 46px;
                width: 100%;
            }
        }
    }

    &_wide {
        .slick-dots {
            li {
                >div {
                    @include tabletAndAbove {
                        width: 152px;
                    }
                }
            }
        }
    }
}
