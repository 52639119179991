@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SliderNext {
    cursor: pointer;
    position: absolute;
    background: $white;
    border: 1px solid $default-secondary-base-color;
    inset-inline-end: -32px;
    inset-block-start: 50%;
    transform: translateY(-50%);
    display: flex;
    height: 64px;
    width: 64px;
    align-items: center;
    justify-content: center;
    z-index: 2;

    svg {
        width: 24px;

        path {
            fill: $black;
        }
    }

    &:hover,
    &:focus {
        svg {
            path {
                fill: $color-primary;
            }
        }
    }

    &_disabled {
        svg {
            path {
                fill: $gray6;
            }
        }
    }
}
