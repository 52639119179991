@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --popup-background: #{$black80};
    --popup-header-background: #{$color-primary};
    --popup-header-color: #{$white};

    @include desktop {
        --popup-content-padding: 16px;
    }
}

.Popup {
    &-Content {
        padding: 0;
        border-radius: unset;
        overflow-x: hidden;
    }

    &-Header {
        padding: 8px 8px 8px 16px;
        background-color: var(--popup-header-background);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            display: none;
        }
    }

    &-Heading {
        margin-block-end: 0;
        color: var(--popup-header-color);
        font-size: 20px;
        font-weight: 400;
    }

    &-Body {
        padding: var(--popup-content-padding);
        width: 100%;
    }

    .Popup-CloseBtn {
        position: static;

        svg {
            fill: var(--popup-header-color);
        }
    }

    &.ShareWishlistPopup {
        @include mobile {
            inset-block-start: 0;
        }

        .Popup-Header {
            @include mobile {
                display: flex;
            }
        }
    }
}

.scrollDisabled {
    .Header-Button_type_back {
        @include mobile {
            display: block;
        }
    }

    .Header-Nav {
        @include flex($wrap: nowrap, $align: center);
    }

    .CategoryFilterOverlay-TotalProducts {
        display: none;
    }
}
