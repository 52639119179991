@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductConfigurableAttributes {
    &-SwatchList {
        @include flex($wrap: wrap, $dir: column, $align: flex-start, $justify: center);

        @include desktop {
            padding: 0;
        }

        .ProductAttributeValue {
            @include flex($align: center);

            margin-block-end: 12px;
        }

        .ExpandableContentShowMore {
            padding-inline-start: 3px;
            padding-block-start: 3px;
        }
    }

    &-DropDownList {
        &_is_brand {
            .ProductAttributeValue-Label {
                display: block;

                &::first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }

    .ExpandableContentShowMore {
        max-height: 500px;
        overflow-y: auto;
        width: 100%;

        &-ShowAllButton {
            &_state_isOpen {
                position: sticky;
                inset-block-end: 0;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    inset-inline-start: -1px;
                    inset-inline-end: 0;
                    inset-block-start: -12px;
                    height: 11px;
                    width: calc(100% + 1px);
                    background: $white;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    inset-inline-end: -12px;
                    inset-block-start: -12px;
                    height: 59px;
                    width: 11px;
                    background: $white;
                }

                &:not([disabled]):hover {
                    background-color: #E4E4F8;
                }
            }
        }

        .Button {
            &_isHollow {
                width: 210px;
            }
        }
    }
}

[dir="ltr"] .ProductConfigurableAttributes {
    &-SwatchList {
        @include desktop {
            padding: 0;
        }
    }
}
