@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/mixins';

.Announcement {
    --announcement-content-padding: 10px 35px;
    --announcement-close-icon-color: #{$white};
    --announcement-close-margin-right: 32px;
    --announcement-close-icon-size: 30px;

    &-Content {
        a {
            text-decoration: underline;
        }
    }
}
