@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TextPlaceholder {
    pointer-events: none;

    &::after {
        font-family: 'Courier New', Courier, monospace;
        color: transparent;
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
    }

    &_loaded {
        pointer-events: all;

        &::after {
            opacity: 0;
        }
    }

    &_length {
        &_full {
            &, &::after {
                content: 'aa';
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
            }
        }

        &_block::after {
            content: 'aa';
        }

        &_short::after {
            content: 'aaaaaaaaaaaaa';

            @include mobile {
                content: 'aaaaaaaa';
            }
        }

        &_medium::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-wrap: break;

            @include mobile {
                content: 'aaaaaaaaaaaaaaaa';
            }
        }

        &_long::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

            @include mobile {
                content: 'aaaaaaaaaaaaaaaaaaaaaaaa';
            }
        }

        &_paragraph::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;

            @include mobile {
                content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            }
        }
    }
}
