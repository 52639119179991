@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --required-text-color: var(--primary-error-color);
}

.Field {
    &-Label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-block-end: 4px;

        &Container {
            display: none;
        }
    }

    &_hasLabel {
        .Field-LabelContainer {
            display: block;
        }

        .SiblingLabel {
            display: none;
        }
    }

    &-InputWrapper {
        position: relative;

        .Field-Label {
            position: absolute;
            inset-inline-start: 16px;
            inset-block-start: 50%;
            transform: translateY(calc(-50% + 1px));
            font-size: 16px;
            line-height: 24px;
            transition: .15s ease-in-out;
            pointer-events: none;

            &::before {
                content: '';
                width: calc(100% + 8px);
                height: 3px;
                position: absolute;
                inset-inline-start: 50%;
                inset-block-start: 50%;
                transform: translate(-50%, calc(-50% - 2px));
                background-color: $white;
                z-index: -1;
            }

            &_isRequired {
                position: relative;
                inset-inline-start: 0;
                color: $gray;

                &::before {
                    display: none;
                }
            }
        }

        input {
            font-size: 16px;

            &::placeholder {
                color: transparent;
                opacity: 0;
            }

            &:-ms-input-placeholder {
                color: transparent;
            }

            &::-ms-input-placeholder {
                color: transparent;
            }

            &:disabled {
                background-color: $gray-light;
                box-shadow: inset 2px 2px 8px rgba($color: #000000, $alpha: .08);

                &~.Field-Label {
                    color: $gray;

                    &::before {
                        display: none;
                    }
                }
            }

            &:focus~.Field-Label,
            &:not(:placeholder-shown)~.Field-Label {
                transform: translateY(calc(-50% - 23px));
                font-size: 12px;
                line-height: 20px;
                color: $gray;

                .Field-Label_isRequired {
                    font-size: $fs-xxs;
                    line-height: 20px;
                }
            }
        }
    }

    &-Error {
        &Messages {
            margin-block-start: 2px;
            text-align: end;
            line-height: 20px;
        }
    }

    &-Icon {
        position: absolute;
        inset-inline-end: 18px;
        inset-block-start: 14px;
    }

    &-CheckboxLabel,
    &-RadioLabel {
        font-size: 16px;
        line-height: 20px;

        a {
            z-index: 10;
        }
    }

    [type='checkbox'],
    [type='radio'] {
        &+.input-control,
        &:hover+.input-control,
        &:checked+.input-control,
        &[disabled]:checked+.input-control {
            border-color: $gray-light;
        }

        &+.input-control {
            width: 16px;
            height: 16px;
            margin-inline-end: 8px;
        }

        &:disabled+.input-control {
            background-color: $gray-light;
            pointer-events: none;
        }

        &:disabled:not(:checked):hover+.input-control::after {
            display: none;
        }
    }

    [type='radio'] {
        &+.input-control {
            border-color: $gray-light;
            display: flex;

            &::after {
                background-color: $color-primary;
                width: 8px;
                height: 8px;
                border-radius: 20px;
                inset-inline-start: 50%;
                inset-block-start: 50%;
                transform: translate(-50%, -50%);
                box-shadow: none;
                opacity: 0;
                transition: .3s ease-in-out;
            }
        }

        &:checked+.input-control {
            border-color: $color-primary;

            &::after {
                opacity: 1;
                inset-inline-start: 50%;
                inset-block-start: 50%;
            }
        }
    }

    &-OuterWrapper {
        &-Label_hasLabel {
            .Field-LabelContainer {
                display: block;
            }

            .SiblingLabel {
                display: none;
            }
        }

        &-Label_isLeft {
            .Field {
                display: flex;
                align-items: center;
            }

            .Field .Field-Label {
                margin: 0 16px 0 0;
            }
        }

        &-Label_isLeft,
        &-Label_isTop {
            .Field-LabelContainer {
                position: relative;

                label {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    padding-block-end: 4px;
                    color: $black;
                    inset-block-start: auto;
                    inset-inline-start: auto;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &-IconField {
        position: relative;
        max-width: 100%;
        width: 480px;
        height: 52px;
        margin-block-end: 40px;

        &.Field-ErrorIconField {
            input:focus {
                border-color: $error;
            }
        }

        input {
            width: 100%;
        }

        .Field {
            &-LabelContainer {
                display: none;
            }

            &-FieldIcon {
                position: absolute;
                inset-inline-end: 0;
                inset-block-start: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-FieldMessages {
                position: absolute;
                inset-inline-end: 0;
                inset-block-end: -22px;
                font-size: 12px;
                line-height: 20px;
            }

            &-ErrorMessage {
                color: $error;
            }

            &-InputIcon {
                margin-inline-end: 18px;
            }
        }
    }

    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border-color: var(--input-border-color);
                }
            }
        }
    }
}
