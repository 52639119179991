@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ArrangementProductCard {
    &-ContentWrapper {
        .ProductCard {
            &-Content {
                margin-block-start: -50px;
                background: transparent;
                z-index: 5;
                padding-inline-start: 0;
            }

            &-PriceWrapper {
                width: auto;
                justify-content: flex-start;
                flex-direction: row;

                .ProductCard {
                    &-Price {
                        background: $white;
                        width: auto;
                        padding: 0 16px;
                    }
                }

                .ProductPrice {
                    &-PriceValue {
                        @include mobile {
                            font-size: $fs-xl;
                            line-height: 30px;
                        }
                    }

                    &-HighPrice {
                        @include mobile {
                            font-size: $fs-s;
                            line-height: 22px;
                        }
                    }
                }
            }

            &-Discount {
                inset-block-end: 60px;
                inset-inline-start: 16px;
                background: $white;
                z-index: 5;
            }

            &-ProductNameContainer,
            &-Reviews {
                margin-inline-start: 16px;
            }

            &-Name {
                height: 24px;
                line-height: 24px;
                -webkit-line-clamp: 1;
            }

            &-Footer {
                padding-block-start: 0;
            }

            &-ProductActions {
                inset-inline-end: 16px;
                inset-block-start: 16px;
            }

            &-FigureReview {
                padding: 0;
            }

            &-Figure {
                overflow: hidden;
            }
        }
    }

    &-Tag {
        background: #fff;
        opacity: .75;
        padding: 2px 4px;
        margin: 0 6px 6px 0;
        font-size: $fs-xs;

        @include mobile {
            font-size: $fs-xxs;
        }
    }

    &-Figure {
        overflow: hidden;
    }

    &-LabelsAndTagsWrapper {
        inset-inline-start: 16px;
        inset-block-start: 16px;
        z-index: 4;
        position: absolute;

        .ArrangementProductCard {
            &-Tags {
                @include flex($wrap: wrap);

                @include desktop {
                    padding-inline-end: 45px;
                }
            }

            &-Tag {
                position: static;
                opacity: .75;
                padding: 2px 4px;
                font-size: $fs-xs;
                border: 0;

                @include mobile {
                    font-size: $fs-xxs;
                }

                &:hover {
                    opacity: 1;
                    background: $white;
                }
            }

            &-ToggleButton {
                @include mobile {
                    height: 24px;
                    width: 24px;

                    >span {
                        padding: 0;
                        margin-block-start: -1px;
                    }
                }
            }
        }

        .ProductLabel {
            position: static;

            @include mobile {
                margin: 0;
            }
        }
    }
}
