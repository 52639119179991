body {
    *::-webkit-scrollbar {
        width: 4px;
        height: 2px;
    }

    *::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: $scrollbar-track-color;
    }

    *::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
        border-radius: 8px;
        border-width: 0;
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: $color-primary-lighter;
    }

    *::-webkit-scrollbar-thumb:active {
        background-color: $color-primary-light;
    }
}
