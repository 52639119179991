@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ImageMagnify {
    display: inline-block;
    cursor: zoom-in;
    position: relative; 

    &-ImageWrapper {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
    }

    &-Image {
        height: 100%;
    }

    &-Result {
        border: 1px solid $gray-light2;
        background-color: #fff;
        background-repeat: no-repeat;
        pointer-events: none;
        position: absolute;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
        transition-delay: 500ms;
    }

    .img-zoom-lens {
        position: absolute;
        border: 1px solid rgba($gray-light2, 0.5);
        z-index: 1;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
        transition-delay: 500ms;
    }

    &_shown {
        .ImageMagnify-Result, .img-zoom-lens {
            opacity: 1;
        }
    }
}
