@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchItem {
    margin: 9px 0;
    font-size: $fs-xs;
    color: $gray;

    &-Title {
        font-size: $fs-xs;
        transition: color .3s ease-in-out;
        margin: 0;
        color: $gray;
        font-weight: $fw-light;
    }

    &-Link:hover h4 {
        color: $color-primary;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;

        &_isImage {
            grid-template-columns: 48px calc(100% - 60px);
            grid-column-gap: 12px;
        }
    }

    &:first-of-type {
        margin-block-start: 0;
    }

    &:last-of-type {
        margin-block-end: 0;
    }
}
