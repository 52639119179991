@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.SliderPrevArrow {
    cursor: pointer;
    position: absolute;
    background: $white;
    border: 1px solid $default-secondary-base-color;
    inset-inline-start: -18px;
    inset-block-start: 50%;
    transform: translateY(-50%);
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    z-index: 2;

    svg {
        width: 16px;
    }

    &_disabled,
    &:hover {
        opacity: 0.4;
    }
}
