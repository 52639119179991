$gap: 16px;

[data-content-type] {
    iframe {
        aspect-ratio: 16/9;
        width: 100%;
    }
}

[data-content-type="banner"] {
    &[data-appearance="poster"] {
        [data-element="overlay"] {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-block: 32px;
            padding-inline: 32px;

            [data-element="content"] {
                margin-block-end: 32px;
            }
        }
    }

    &[data-appearance="collage-centered"],
    &[data-appearance="collage-right"],
    &[data-appearance="collage-left"] {
        [data-element="wrapper"] {
            display: flex;
            align-items: flex-start;
        }

        [data-element="overlay"] {
            padding-block: 32px;
            padding-inline: 32px;

            @include desktopAndAbove {
                width: 40%;
            }

            [data-element="content"] {
                margin-block-end: 32px;
            }
        }
    }

    &[data-appearance="collage-centered"] {
        [data-element="wrapper"] {
            justify-content: center;
        }
    }

    &[data-appearance="collage-left"] {
        [data-element="wrapper"] {
            justify-content: flex-start;
        }
    }

    &[data-appearance="collage-right"] {
        [data-element="wrapper"] {
            justify-content: flex-end;
        }
    }
}

[data-content-type="buttons"] {
    margin-block-end: -10px;
    margin-inline-end: -10px;
}

[data-content-type="button-item"] {
    margin-block-end: 10px;
    margin-inline-end: 10px;

    [data-element="link_text"] {
        word-break: break-all;
    }
}

[data-content-type="divider"] {
    [data-element="line"] {
        border-style: solid;
        margin-block: 20px;
    }
}

[data-content-type="column"] {
    @include mobileAndTablet {
        flex: 1 0 100%;
        width: 100%;
    }
}

[data-content-type="column-group"] {
    height: 100%;
    gap: $gap;

    @include mobileAndTablet {
        flex-wrap: wrap;
    }
}

.CmsPage-Wrapper_page_width_full {
    [data-content-type="row"] {
        &[data-appearance='contained'] {
            max-width: var(--content-wrapper-width);
            padding-inline: 32px;
            margin-inline: auto;

            @include mobile {
                padding-inline: 16px;
            }
        }

        &[data-appearance='full-width'],
        &[data-appearance='full-bleed'] {
            &>[data-element='inner'] {
                max-width: var(--content-wrapper-width);
                padding-inline: 32px;
                margin-inline: auto;

                @include mobile {
                    padding-inline: 16px;
                }
            }
        }

        &:not(:last-child) {
            margin-block-end: $gap;
        }
    }
}

.pagebuilder {
    &-mobile {
        &-hidden {
            @include mobileAndTablet {
                @include visually-hidden;
            }
        }

        &-only {
            @include desktopAndAbove {
                @include visually-hidden;
            }
        }
    }
}

.mobile-hidden {
    @include mobile {
        /* stylelint-disable */
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
        pointer-events: none !important;
        /* stylelint-enable */
    }
}
