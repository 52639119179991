@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Tabs {
    $tabs: &;

    display: grid;

    &:not(&_isPageBuilder) {
        grid-gap: 32px;
        align-items: start;

        @include desktopAndAbove {
            grid-template-columns: 300px auto;
        }

        #{$tabs} {
            &-Sidebar {
                padding: 12px 0;
            }

            &-Link {
                width: 100%;

                &_isSelected,
                &:hover {
                    font-weight: 600;
                }
            }
        }
    }

    &_isPageBuilder {
        display: flex;
        flex-direction: column;

        #{$tabs} {
            &-Sidebar {
                display: flex;
                align-items: center;
                justify-self: flex-start;
                border-bottom: 0;
                background: $gray-light7;
            }

            &-Link {
                height: 48px;
                color: $black2;

                &_isSelected {
                    color: $color-primary;
                }

                @include desktop {
                    &:hover {
                        color: $color-primary;
                    }
                }
            }

            &-Content {
                padding-block: 16px;
                padding-inline: 16px;
                background: $white;
                border: 1px solid var(--primary-divider-color);
                overflow: hidden;
            }
        }
    }

    &-Sidebar {
        background-color: var(--color-white);
        border: 1px solid var(--primary-divider-color);
    }

    &-Link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 24px;
        height: 38px;
        font-size: 14px;
        text-align: start;
        cursor: pointer;

        .ChevronIcon {
            fill: $color-primary;
        }
    }
}
