.HpAwards {
    margin: 16px auto 24px;

    &-Wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &_inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin: 0 auto;
            margin-block-start: 16px;
        }
    }

    .HpAwards-SectionTitle {
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
    }

    &-Item {
        width: 50%;
        padding: 16px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-Image {
        max-width: 80px;
        max-height: 76px;
    }

    &-Title {
        text-align: center;
        color: #000;
        margin-block-start: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    &-SubTitle {
        display: block;
    }
}

@include tabletAndAbove {
    .HpAwards {
        margin: 28px auto 26px;
        border-bottom: 1px solid $gray-light;

        &-Wrapper {
            margin-block-start: 68px;
            margin-block-end: 30px;
            gap: 10px;
        }

        .HpAwards-SectionTitle {
            text-align: center;
            font-size: 28px;
            line-height: 32px;
        }

        &-Item {
            width: 216px;
            max-width: calc(25% - 10px)
        }

        &-Title {
            font-size: 16px;
        }
    }
}
