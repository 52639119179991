@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

:root {
    --announcement-content-padding: 10px 25px;
    --announcement-close-icon-color: #{$white};
    --announcement-close-icon-size: 24px;
    --announcement-close-margin-right: 20px;
    --announcement-font-size: 14px;
    --announcement-min-height: 40px;
    --announcement-mobile-height: 60px;
}

.Announcement {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset-block-start: 0;
    width: 100%;
    height: auto;
    z-index: 1000;
    background-color: $default-primary-base-color;
    min-height: var(--announcement-min-height);

    @include mobile {
        height: var(--announcement-mobile-height);
    }


    &-Content {
        width: 100%;
        text-align: center;
        padding: var(--announcement-content-padding);
        font-size: var(--announcement-font-size);
        height: auto;
        color: $white;

        @include mobile {
            overflow-wrap: break-word;
            overflow: hidden;
            padding-inline: 24px;
        }

        p,
        span,
        a {
            font-size: inherit;
            color: inherit;
            line-height: 1.2;
        }

        p {
            margin-block-end: 0;
        }

        a {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        img {
            width: auto;
            height: auto;
        }

        div>p {
            @include mobile {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 40px;
                text-overflow: ellipsis;
                white-space: normal;
                width: 100%;
                overflow: hidden;
                padding-inline-end: 1px;
                line-height: 20px;
            }
        }

        &_expanded {
            @include mobile {
                div>p {
                    @include mobile {
                        display: block;
                        height: auto;
                    }
                }
            }
        }
    }

    &-CloseIcon {
        margin-inline-end: var(--announcement-close-margin-right);

        @include mobile {
            margin-block-end: 20px;
            margin-inline-end: 12px;
        }

        svg {
            fill: var(--announcement-close-icon-color);
            height: var(--announcement-close-icon-size);
            width: var(--announcement-close-icon-size);
            transition: fill .3s ease-in-out;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &-ExpandButton {
        @include flex($justify: center, $align: center);

        position: absolute;
        inset-block-end: -35px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        background-color: $default-primary-base-color;
        border-radius: 50%;
        height: 30px;
        width: 30px;

        svg {
            fill: $white;
            transform: rotate(180deg);
            transition: fill .3s ease-in-out;

            path {
                fill: $white;
            }
        }
    }
}
