.DeliveryType {
    margin-block-end: 32px;

    @include desktopAndAbove {
        margin-block-end: 48px;
    }

    &-Heading {
        background: red;
    }

    &-Items {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        border: 1px solid var(--primary-divider-color);
        border-top: 0;
    }

    &-Item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 8px;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 16px;
        background: $gray-lighter;
        text-align: center;
        border-top: 1px solid var(--primary-divider-color);

        &:not(:last-child) {
            border-right: 1px solid var(--primary-divider-color);
        }

        &:nth-child(odd):last-child {
            flex: 0 0 100%;
            max-width: 100%;
            border-top: 1px solid var(--primary-divider-color);
        }

        @include mobileAndTablet {
            &:nth-child(even) {
                border-right: 0;
            }
        }

        @include tabletAndAbove {
            padding: 24px;
        }

        @include desktopAndAbove {
            flex: 0 0 25%;
            max-width: 25%;

            &:nth-child(4n + 4) {
                border-right: 0;
            }
        }

        &-Image {
            display: block;
            height: 50px;

            img {
                object-fit: contain;
            }
        }

        &-Name {
            font-size: $fs-l;
            font-weight: $fw-medium;
            line-height: 1.4;

            @include mobile {
                font-size: $fs-s;
                font-weight: $fw-medium;
                line-height: 1.4;
            }
        }

        &-Price {
            font-size: $fs-s;
            font-weight: $fw-regular;
            line-height: 1.4;

            @include mobile {
                font-size: $fs-xs;
            }

            span {
                font-size: $fs-m;
                font-weight: $fw-semi-bold;
                color: $color-primary;
                line-height: 1.4;

                @include mobile {
                    font-size: $fs-xs;
                }
            }
        }
    }

    &-Categories {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        border: 1px solid var(--primary-divider-color);
        border-top: 0;
    }

    &-Category {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 8px;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 16px;
        text-align: center;
        border-bottom: 1px solid var(--primary-divider-color);

        &:not(:last-child) {
            border-right: 1px solid var(--primary-divider-color);
        }

        @include mobileAndTablet {
            &:nth-child(even) {
                border-right: 0;
            }
        }

        @include tabletAndAbove {
            padding: 24px;
        }

        @include desktopAndAbove {
            flex: 0 0 25%;
            max-width: 25%;

            &:nth-child(4n + 4) {
                border-right: 0;
            }
        }

        &-Image {
            padding-block-end: 100%;
            width: 100%;

            img {
                position: absolute;
                inset: 0;
            }
        }

        &-Name {
            font-size: $fs-l;
            font-weight: $fw-medium;
            line-height: 1.4;

            @include mobile {
                font-size: $fs-s;
                font-weight: $fw-medium;
                line-height: 1.4;
            }
        }
    }
}
