@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AddToCart {
    &:disabled {
        border-color: var(--hollow-button-disabled-border);

        span {
            color: var(--hollow-button-hover-color);
        }
    }
}

