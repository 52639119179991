@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ContactForm {
    &_isContactPage {
        input[name="vat_id"] {
            display: none;

            &+label {
                display: none;
            }
        }
    }

    .Consents {
        margin-block-end: 12px;

        &-Field {
            .input-control {
                min-height: 18px;
                margin-block-start: 3px;
            }
        }

        p {
            padding-inline-start: 26px;
            margin-block-end: 0;
        }

        &-FieldWrapper {
            p {
                padding-inline-start: 0;
            }
        }

        >div {
            padding: 6px 0;
        }
    }
}
