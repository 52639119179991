@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

$dot-background: $default-secondary-base-color;
$dot-background-active: $default-primary-base-color;

.Slider {
    padding-block-end: 32px;
    margin-block-end: 64px;

    &-Content {
        background: rgba(255, 255, 255, 0.8);
        padding: 24px;
        width: 100%;

        h5 {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin-block-end: 5px;
        }

        h2 {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            text-align: start;
            color: $black;
            margin-block-start: 5px;
            margin-block-end: 5px;
        }
    }

    &-Button {
        margin-block-start: 16px;
    }

    &-Page {
        display: none;
    }
}

[dir="ltr"] .Slider .slick-dots {
    margin: 0;
}

@include tabletAndAbove {
    .Slider {
        &-Content {
            width: 40%;
            height: 100%;
            display: flex;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            inset-block-start: 0;
        }

        .Button {
            align-self: flex-start;
        }

        &-Page {
            display: block;
            padding: 10px 0;
        }
    }
}

@include desktopAndAbove {
    .Slider {
        &-Content {
            max-width: 580px;

            h5 {
                font-size: 24px;
                line-height: 28px;
            }

            h2 {
                font-size: 48px;
                line-height: 56px;
            }
        }
    }
}
