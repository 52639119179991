.FooterCheckout {
    width: 100%;

    &-Wrapper {
        width: 100%;

        @include flex($justify: space-between, $align: center, $wrap: wrap);

        @include mobileAndTablet {
            flex-wrap: wrap;
        }
    }

    &-Box {
        width: 33.33%;

        @include mobileAndTablet {
            width: 50%;
            margin-block-end: 20px;
        }

        &:nth-child(2) {
            @include flex($justify: center, $align: center);

            @include mobileAndTablet {
                justify-content: flex-end;
            }
        }

        &:nth-child(3) {
            @include flex($justify: flex-end, $align: center);

            @include mobileAndTablet {
                width: 100%;
                justify-content: flex-start;
                margin-block-end: 0;
            }

            a {
                padding-inline-start: 40px;
                line-height: 20px;
                font-weight: $fw-light;
                font-size: $fs-xs;

                @include mobileAndTablet {
                    padding-inline-start: 32px;

                    &:first-of-type {
                        padding: 0;
                    }
                }

                @include wide-desktop {
                    padding-inline-start: 87px;
                }
            }
        }
    }

    &-SubBox {
        @include flex($justify: center, $align: center);

        margin: 0 8px;

        @include mobile {
            &:last-of-type {
                display: none;
            }
        }

        @include above1200 {
            margin: 0 16px;
        }

        a {
            color: $color-primary;
            padding-inline-start: 5px;
            line-height: 20px;
            font-weight: $fw-medium;
            font-size: $fs-xs;

            @include above1200 {
                padding-inline-start: 10px;
            }
        }
    }

    &-Text {
        font-size: 20px;
        line-height: 30px;
        margin-block-end: 0;
    }
}
