@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

$no-products-background: #ddd;

.CatalogWidget {
    overflow: hidden;
    margin: 0 -18px;
    padding: 18px 18px 64px;

    &-Item {
        max-width: 350px;
        padding: 12px;
    }

    &-NoProducts {
        font-size: 12px;
        background: $no-products-background;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-block-end: 44px;
    }

    &-Loader {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slick-list {
        overflow: unset;
    }
}
