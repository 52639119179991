@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.CrossSellPopup {
    --popup-content-padding: 16px 24px;

    @include mobile {
        --popup-content-padding: 16px;

        inset-block-start: 0;
        height: 100%;
        z-index: 900;
    }

    .NotificationList {
        @include mobile {
            top: 0;
            inset-block-start: 0;
            inset-inline-start: 0;
            position: fixed;
        }
    }

    &-PriceLine {
        @include flex($dir: row, $align: center, $justify: space-between);
        
        @include mobile {
            flex-basis: 100%;
            margin: 0 16px 8px;
        }

        .ProductPrice {
            @include flex($dir: column, $align: flex-end);

            @include mobile {
                flex-direction: row-reverse;
                align-items: center;
                margin-block-end: 8px;
                margin-block-start: 0;
            }

            &-Price {
                margin-inline: 0 !important;
            }

            &-PriceValue {
                font-weight: $fw-medium;
                font-size: $fs-xl;
                line-height: 24px;
                letter-spacing: -0.02em;

                @include mobile {
                    font-size: $fs-xs;
                    margin: 0;
                    margin-inline-start: 8px;
                }
            }

            &-HighPrice {
                color: $gray;
                font-weight: $fw-light;
                font-size: $fs-s;
                letter-spacing: -0.02em;
                order: -1;

                @include mobile {
                    order: 1;
                    font-size: $fs-xxxs;
                    line-height: 17px;
                }
            }
        }
    }

    &-ItemData {
        flex: 1;
    }

    &-Label {
        position: absolute;
        inset-block-start: 5px;
        inset-inline-start: 5px;
        background-color: $error;
        font-size: $fs-xxs;
        font-weight: $fw-semi-bold;
        color: $white;
        padding: 0 8px;
        height: 23px;
        z-index: 4;

        @include flex($align: center, $justify: center);
    }

    &-Availability {
        color: $gray;
        font-size: $fs-xs;
        line-height: 24px;

        @include mobile {
            font-size: $fs-xxs;
        }

        span {
            color: $accept;
            font-weight: $fw-medium;
        }
    }

    &-Products {
        @include flex();

        width: 100%;
    }

    &-Slider {
        .slick-list {
            margin-left: 59px;
            margin-right: 39px;

            @include mobile {
                margin: 0 auto;
            }
        }

        .ProductCard {
            &-PriceWrapper {
                min-height: 47px;
            }
        }
    }

    &-ProductCardWrapper {
        width: 25%;
        padding: 0 8px;

        @include mobile {
            width: 50%;
        }

        @include tablet {
            width: 33.33%;
        }
    }

    &-SliderPrevButton {
        position: absolute;
        top: 30%;
        cursor: pointer;

        @include mobile {
            width: 40px;
            height: 40px;
            background: #fff;
            inset-inline-start: -14px;
            box-shadow: 0px 111px 80px rgba(0, 0, 0, 0.07),
                0px 50.7393px 28.7708px rgba(0, 0, 0, 0.0566335),
                0px 27.6148px 13.9084px rgba(0, 0, 0, 0.0542652),
                0px 15.4729px 7.2513px rgba(0, 0, 0, 0.0540841),
                0px 8.36082px 3.66267px rgba(0, 0, 0, 0.0529444),
                0px 4.04148px 1.62988px rgba(0, 0, 0, 0.0481137),
                0px 1.44911px 0.51686px rgba(0, 0, 0, 0.0351498);
        }
    }

    &-SliderNextButton {
        position: absolute;
        right: 0;
        top: 30%;
        cursor: pointer;

        @include mobile {
            width: 40px;
            height: 40px;
            background: #fff;
            inset-inline-end: -14px;
            box-shadow: 0px 111px 80px rgba(0, 0, 0, 0.07),
                0px 50.7393px 28.7708px rgba(0, 0, 0, 0.0566335),
                0px 27.6148px 13.9084px rgba(0, 0, 0, 0.0542652),
                0px 15.4729px 7.2513px rgba(0, 0, 0, 0.0540841),
                0px 8.36082px 3.66267px rgba(0, 0, 0, 0.0529444),
                0px 4.04148px 1.62988px rgba(0, 0, 0, 0.0481137),
                0px 1.44911px 0.51686px rgba(0, 0, 0, 0.0351498);
        }
    }

    .SliderProgressBar {
        margin-top: 32px;
        margin-bottom: 16px;

        &-Filler {
            @include mobile {
                background-color: $color-primary;
            }
        }
    }

    &-Header {
        font-weight: var(--paragraph-font-weight);
        font-size: $fs-m;
        line-height: 24px;
        color: $black;
        margin-top: 16px;
        margin-bottom: 16px;

        @include mobile { 
            font-size: $fs-s;
            line-height: 20px;
            text-align: center;
        }
    }

    &-ItemName {
        width: 100%;
        font-weight: $fw-medium;
        font-size: $fs-s;
        line-height: 24px;
        color: $black;
        margin-block: 12px;

        @include mobile {
            font-size: $fs-xs;
            font-weight: $fw-regular;
            margin-block-start: 8px;
        }
    }

    .Popup {
        &-Content {
            padding: 0px;

            @include tabletAndAbove {
                width: 679px;
            }

            @include above1200 {
                width: 1156px;
            }
        }

        &-Header {
            @include flex($justify: space-between, $align: center);

            background-color: $white;
            padding: 10px 8px 8px 24px;
        }

        &-Heading {
            color: $black;
            font-size: $fs-xl;
            line-height: 28px;
            letter-spacing: -0.02em;
            margin-block-start: 10px;

            @include mobile {
                font-size: $fs-m;
                font-weight: $fw-medium;
                line-height: 24px;
                inset-block-start: -5px;
                margin-block-start: 0;
            }

            svg {
                inset-block-start: 5px;
                margin-inline-end: 12px;
                width: 28px;

                @include mobile {
                    inset-block-start: 7px;
                    width: 24px;
                }

                path {
                    fill: $accept;
                }
            }
        }

        &-CloseBtn {
            height: 30px;

            svg {
                fill: $black;
            }
        }
    }

    &-Item {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 24px;
        position: relative;
        background-color: $white;
        border: 1px solid $gray-lighter;
        padding: 9px;
        box-shadow: 0px 18px 44.67px 0px #00000014;
        font-weight: 500;

        @include mobile {
            flex-wrap: wrap;
            padding: 0;
            height: auto;
            margin-block-start: 8px;
        }

        .Image {
            padding: 0;
            width: 113px;
            height: 102px;

            @include mobile {
                width: 100px;
            }
        }
    }

    &-Footer {
        margin-block-start: 16px;
    }

    &-Buttons {
        @include flex($dir: row, $justify: flex-end);
        gap: 16px;

        @include mobile {
            flex-direction: column;
        }

        .CrossSellPopup {
            &-ContinueButton {
                background-color: $white;
                border: 1px solid var(--button-background);
                border-block-end-width: 2px;
                color: var(--button-background);
            }
        }
    }

    .ProductLabel {
        margin: 5px 0;

        @include mobile {
            margin: 0;
        }

        &-Text {
            padding: 4px 8px;
            line-height: 12px;
        }
    }
}
