.CmsPage {
    .CmsPage {
        &-Heading {
            @include mobile {
                display: block;
            }
        }

        &-Content {
            .CmsTextPage {
                h3 {
                    margin: 32px 0 16px;

                    @include mobile {
                        font-size: var(--h3-font-size-mobile);
                    }
                }

                h4 {
                    margin: 32px 0 16px;
                    display: inline-block;
                    text-transform: uppercase;

                    @include mobile {
                        font-size: $fs-m;
                    }
                }

                p {
                    margin: 0 0 16px;
                    line-height: 24px;

                    @include mobile {
                        font-size: $fs-s;
                    }
                }

                ol {
                    margin-inline-start: 0;

                    li {
                        &::before {
                            position: static;
                            display: inline-block;
                            padding-inline-end: 5px;
                        }
                    }
                }

                ul {
                    padding-inline-start: 10px;
                }

                table {
                    width: 100%;
                    margin: 16px 0;

                    @include mobile {
                        width: 500px;
                    }

                    thead {
                        th {
                            padding: 14px 16px;
                            font-size: $fs-s;
                            font-weight: $fw-medium;
                            background-color: $color-primary;
                            color: $white;
                            border-radius: 0;
                        }
                    }

                    th,
                    td {
                        padding: 14px 16px;
                        font-size: $fs-s;

                        @include mobile {
                            padding: 8px 4px;
                            font-size: $fs-xs;
                            width: 50%;
                        }
                    }

                    th {
                        font-weight: $fw-regular;
                        width: 40%;

                        @include mobile {
                            width: 50%;
                        }
                    }

                    td {
                        width: 60%;

                        @include mobile {
                            width: 50%;
                        }
                    }

                    tr:nth-child(odd) {
                        background-color: #E8E9E9;
                    }
                }
            }
        }
    }
}
