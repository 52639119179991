@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.CrossSellPopup {
    &-ProductCard {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        align-content: flex-end;
    }

    &-ProductImage {
        order: 0;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &-ProductName {
        order: 2;
        width: 100%;
        font-size: $fs-xs;
        line-height: 20px;
        color: $black;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 40px;
        margin-block: 5px 16px;

        @include mobile {
            font-size: $fs-xs;
            line-height: 18px;
            margin-block-end: 8px;
        }
    }

    &-AddToCart {
        @include flex($wrap: wrap, $dir: column, $align: flex-start, $justify: center);
        order: 4;
        width: 100%;

        button {
            @include flex($align: center, $justify: center);

            padding: 11px 16px;
            background-color: $default-primary-base-color;

            @include mobile {
                --button-height: 32px;
                min-width: unset;
                padding: 6px 16px;
            }

            span {
                font-weight: $fw-medium;
                font-size: $fs-s;
                text-align: center;
                letter-spacing: -0.03em;

                @include mobile {
                    font-size: $fs-xs;
                }
            }
        }

        .Field {
            margin: 0;
            display: block;

            &-Wrapper {
                width: 100%;
            }

            &-InputWrapper {
                @include flex($justify: center);

                align-items: center;
                height: 44px;
                margin-block-start: 9px;

                button {
                    cursor: pointer;
                    background: transparent;
                    border: 0;
                    padding: 5px;
                    height: 100%;
                }

                input {
                    border: 1px solid $gray-light;
                    font-size: 16px;
                    font-weight: 300;
                    height: 100%;
                }

                >svg {
                    display: none;
                }
            }
        }
    }

    .ProductPrice {
        @include flex($dir: row-reverse, $justify: flex-end, $align: flex-end);
        margin-top: 4px;

        del {
            margin-left: 0;
        }

        &-Price {
            margin-inline-end: 10px;
            font-size: $fs-m;
            font-weight: $fw-light;
            letter-spacing: 0.02em;
            line-height: 24px;
            top: -2px;

            @include mobile {
                font-size: $fs-xxxs;
                line-height: 14px;
            }
        }

        &-PriceValue {
              margin-block-start: 4px;
              font-weight: $fw-medium;
              font-size: $fs-xl;
              letter-spacing: -0.02em;
              line-height: 30px;

              @include mobile {
                  font-size: $fs-xs;
                  line-height: 21px;
              }
        }

        &-HighPrice {
            font-weight: $fw-light;
            font-size: $fs-m;
            line-height: 28px;
            letter-spacing: -0.03em;
            order: -1;

            @include mobile {
                font-size: $fs-xxxs;
                line-height: 20px;
            }
        }
    }

    .ProductCard {
        &-PriceWrapper {
            order: 1;        
            justify-content: flex-end;

            @include desktop {
                min-height: 47px;
            }
        }
    }
}
