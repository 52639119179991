@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --accordion-icon-background: #{$color-primary};
    --accordion-icon-color: #{$white};
    --accordion-header-background: #{$color-primary};
    --accordion-header-text: #{$white};
    --accordion-content-background: #{$white};
}

.Accordion {
    &_isSecondary {
        --accordion-header-background: #{$white};
        --accordion-header-text: #{$black};

        .Accordion {
            &-Header {
                padding-inline: 0;
            }

            &-Content {
                padding-inline: 0;
            }
        }
    }

    &-Header {
        display: flex;
        align-items: flex-start;
        background: var(--accordion-header-background);
        padding: 8px 16px;
        font-size: $fs-s;
        font-weight: $fw-medium;
        line-height: 1.4;
        color: var(--accordion-header-text);
        cursor: pointer;
    }

    &-Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-end: 16px;
        height: 20px;
        width: 20px;
        min-width: 20px;
        background: var(--accordion-icon-background);
        color: var(--accordion-icon-color);

        &::before {
            content: "+";
            height: 100%;

            @include desktopAndAbove {
                margin-inline-start: 1px;
            }
        }

        &_isOpen::before {
            content: "-";
        }
    }

    &-Content {
        background: var(--accordion-content-background);
        padding: 8px 16px;

        .Accordion {
            --accordion-header-background: #{$white};
            --accordion-header-text: #{$black};

            &-Header {
                padding-inline: 0;
            }

            &-Content {
                padding-inline: 0;
            }
        }
    }
}
