@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/mixins';

.Countdown {
    position: absolute;
    inset-inline-end: 0;
    inset-block-end: 0;
    padding: 16px;

    @include flex($dir: column);

    @include mobile {
        padding: 7px;
    }

    &-Title {
        display: inline-block;
        margin-inline-start: auto;
        margin-block-end: 10px;
        font-size: $fs-xxs;
        color: $white;
        padding: 4px 8px;
        background-color: $error;
        font-weight: $fw-semi-bold;
    }

    &-Content {
        margin: 0;
    }

    &-Item {
        background-color: $white70;
        border: none;
        width: 48px;
        margin-inline-start: 12px;
        box-shadow: 0 8px 3px rgba(0, 0, 0, 0.05), 0 4px 1px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.03);

        @include mobile {
            width: 40px;
        }
        
        &:not(:last-of-type)::after {
            content: ':';
            position: absolute;
            inset-block: 0;
            inset-inline-end: -9px;
            font-size: 9px;
            font-weight: $fw-bold;

            @include flex($align: center)
        }
    }

    &-Label {
        background: none;
        color: $gray;
        text-transform: lowercase;
        font-size: 8px;
        order: 2;
        margin-block-end: 8px;
    }

    &-Number {
        color: $error;
        font-size: $fs-s;
        font-weight: $fw-medium;
    }

    &-Finished {
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0;
        padding: 16px;
        margin: 16px;
        background-color: $white70;
        border: none;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        color: $error;
        font-size: $fs-s;
        font-weight: $fw-medium;
    }
}
