@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@mixin review-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 68px;
}

.ReviewsSection {
    margin: 47px 0 36px;

    @include mobileAndTablet {
        overflow: hidden;
        margin: 22px 0 16px;
    }

    &-Reviews {
        @include review-grid;

        @include mobileAndTablet {
            display: block;
        }
    }

    &-Head {
        @include review-grid;

        background-image: url('../../assets/icons/quote.svg');
        background-repeat: no-repeat;
        background-position: 23px 0;
        padding-block-start: 12px;

        @include mobile {
            display: block;
            background-size: contain;
            min-height: 68px;
            margin-block-end: 8px;
        }
    }

    &-Header {
        margin-block-end: 61px;

        @include mobile {
            font-size: 22px;
            text-align: start;
        }
    }

    &-TotalAverage {
        grid-column-start: span 2;
        font-size: $fs-s;

        span {
            color: $color-primary;
            font-size: $fs-xxl;
        }
    }

    &-Average {
        padding-block-end: 18px;
        margin-block-end: 16px;
        border-bottom: solid 1px $gray-light;

        .StarIcon {
            width: 20px;
            height: 20px;
        }

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    &-Product {
        font-size: $fs-xs;
        font-weight: $fw-light;
        margin-block-end: 16px;
        color: $gray2;

        a {
            transition: color .3s ease-in-out;

            &:hover {
                color: $color-primary;
            }
        }
    }

    &-Text {
        font-size: $fs-s;
        font-weight: $fw-light;
        margin-block-end: 24px;
    }

    &-Nickname {
        font-size: $fs-s;
    }
}
