@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPrice {
    del {
        font-size: $fs-s;
        font-weight: $fw-light;
        opacity: 1;
        margin-inline-end: 0;
        margin-inline-start: 0;
        color: $gray;
    }

    &-PriceValue {
        font-weight: $fw-medium;
    }

    span.ProductPrice-Price {
        .ProductPrice-PriceValue {
            color: $black
        }
    }

    ins.ProductPrice-Price {
        margin-inline-end: 7px;

        .ProductPrice-PriceValue,
        .ProductPrice-Qmeters,
        .ProductPrice-Qmeters sup {
            color: $red;
        }
    }

    &-Qmeters {
        width: auto;
        padding-inline-start: 2px;

        sup {
            font-size: 60%;
            inset-block-start: -0.5em;
        }
    }
}
