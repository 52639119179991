.ProductActions {
    &-AddToCartCmsBlock {
        background: $color-secondary-lighter;
        height: 100%;
        padding: 158px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Button {
            background: $color-tertiary;
            color: #000;
            border: none;
            text-align: center;

            @include mobile {
                font-size: 16px;
                padding-block: 8px;
                width: 85%;
            }

            &:hover {
                background-color: $color-tertiary;
                color: #000;
            }
        }

        @include mobile {
            padding: 52px 0;
        }
    }

    [name=ProductActionsAddToCartWrapper] .ProductActions-AddToCartWrapper {
        box-shadow: 0px 4px 12px 0px #00000026;
    }

    &-AddToCartCmsText {
        font-size: 18px;
        color: #10069F;
        line-height: 26px;
        margin-block-end: 32px;
        padding-inline: 45px;
        text-align: center;
    }
}
