@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Breadcrumbs {
    @include mobile {
        display: block;
    }

    &-List {
        @include mobile {
            overflow-x: auto;
            white-space: nowrap;
            padding-block-end: 0;
        }

        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar {
            @include mobile {
                display: none;
            }
        }
    }
}
