@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ArrangementProductTags {
    &-Tags {
        @include flex($wrap: wrap);

        &_isExpandable {
            .ArrangementProductTags-ToggleButton {
                display: inline-flex;
            }
        }

        &_isExpandable.isActive {
            .ArrangementProductTags-ToggleButton {
                .ArrangementProductTags {
                    &-ShowTags {
                        display: none;
                    }

                    &-HideTags {
                        display: block;
                    }
                }
            }

            .ArrangementProductTags-Tag {
                display: block;
            }
        }
    }

    &-Tag {
        background: $white;
        border: 1px solid $gray-light;
        padding: 5px;
        margin: 0 6px 6px 0;

        &:hover {
            background: $color-primary-lighter;
        }

        &_isHidden {
            display: none;
        }
    }

    &-Hashtag {
        color: $color-primary;
        font-weight: $fw-bold;
        padding-inline-end: 3px;
    }

    &-ToggleButton {
        display: none;
        height: 27px;
        width: 27px;
        border: 1px solid $gray-light;
        font-weight: $fw-light;
        font-size: $fs-xs;
        cursor: pointer;

        @include mobile {
            height: 29px;
            width: 29px;
        }

        @include mobileAndTablet {
            margin: 0 0 5px;
        }

        >span {
            padding: 0 2px 0 1px;

            @include mobile {
                padding-block-start: 1px;
            }
        }

        .AddIcon,
        .MinusIcon {
            fill: $gray-light;
            pointer-events: none;
        }

        .ArrangementProductTags-HideTags {
            display: none;
        }
    }
}
