@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductStockAvailability {
    b,
    span {
        font-weight: $fw-light;
        font-size: $fs-xs;

        @include mobile {
            font-size: $fs-xxs;
        }
    }

    span.ProductStockAvailability-Value,
    b.ProductStockAvailability-Value {
        &_fast {
            font-weight: $fw-medium;
            color: $green;
            opacity: 0.8;
        }

        &_unavailable {
            color: $red;
        }
    }
}
