.CmsBlock {
    &-Wrapper {
        &_type {
            &_menu_bottom_block {
                width: 100%;
                background: $gray-light3;

                .MenuCmsBottom {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    height: 88px;

                    &_TitleWrapper {
                        width: 20%;

                        p {
                            margin: 0;
                        }
                    }

                    &-Image {
                        max-width: 64px;
                        height: auto;
                    }

                    &_ContentWrapper {
                        width: 80%;
                        padding: 0 5%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    &::before {
                        background: $gray-light3;
                        content: '';
                        position: absolute;
                        width: 100vw;
                        inset-inline-start: 50%;
                        margin-inline-start: -50vw;
                        height: 100%;
                    }
                }
            }
        }
    }
}
