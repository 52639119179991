@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductWishlistButton {
    .ProductWishlistButton {
        &-Button.Button {
            &:hover,
            &:focus {
                background-color: unset;
                height: var(--wishlist-heart-size);
                padding: 0;
            }
        }
    }
}
