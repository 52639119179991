@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ExpandableContentShowMore {
    .Button_isHollow {
        height: 31px;
        margin-block-start: 12px;
        width: 215px;
    }

    &-ExpandableChildren {
        padding: 3px;
        margin-inline-start: -3px;
    }
}
