@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.GroupedProductList {
    &-showMore {
        width: 100%;
        height: 130px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 1) 90%);
        position: absolute;
        inset-block-end: 0;

        @include flex($align: center, $justify: center);

        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            display: none;
        }

        button {
            font-size: $fs-s;
            color: $color-primary;
            border: 1px solid $color-primary;
            padding: 12px 20px;
            cursor: pointer;
            background: $white;
            margin-block-start: 25px;
        }
    }
}

.ProductActions-GroupedItems {
    h2 {
        text-align: center;
    }
}
