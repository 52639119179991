.HpInfographic {
    display: none;
}

@include tabletAndAbove {
    .HpInfographic {
        display: flex;
        align-items: center;
        background-color: $black;
        height: 204px;
        margin: 36px 0 30px;

        .HpInfographic-Icon {
            width: auto;
            height: 25px;
            margin-block-end: 26px;
        }

        &-Item {
            margin: 72px auto 34px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &-Title {
            text-align: center;
            color: $white;
            margin-block-start: 21px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        &-SubTitle {
            display: block;
        }

        &-Separator {
            width: 1px;
            height: 138px;
            display: block;
            border: none;
            background-color: $white;
            margin: 0;
            opacity: 0.2;
        }
    }
}
