@mixin button-disabled {
    background-color: var(--disabled-button-background);
    color: var(--disabled-button-color);
    pointer-events: none;

    border: {
        color: var(--disabled-button-border);
    }
}

@mixin button {
    align-items: center;
    background-color: var(--button-background);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    display: inline-flex;
    color: var(--button-color);
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    min-height: var(--button-height);
    line-height: 24px;
    padding-inline: var(--button-padding);
    text-decoration: var(--button-text-decoration);
    text-transform: none;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;

    @include mobile {
        user-select: none;
        width: 100%;
    }

    border: {
        color: var(--button-border);
        style: solid;
        width: var(--button-border-width);
    }

    border-bottom-width: var(--button-border-bottom-width);

    &:hover,
    &:focus {
        text-decoration: var(--button-hover-text-decoration);
    }

    &:not([disabled]) {
        &:hover {
            @include desktop {
                background-color: var(--button-hover-background);
                border-color: var(--button-hover-border);
                border-radius: var(--button-hover-border-radius);
                color: var(--button-hover-color);
                padding-inline: var(--button-hover-padding);
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_sizeMedium {
        height: var(--medium-button-height);
        font-size: var(--medium-button-size);
    }

    &_sizeSmall {
        height: var(--small-button-height);
        font-size: var(--small-button-size);
    }

    &_isHollow {
        background-color: var(--hollow-button-background);
        border-color: var(--hollow-button-border);
        border-radius: var(--hollow-button-border-radius);
        color: var(--hollow-button-color);
        padding-inline: var(--hollow-button-padding);
        text-decoration: var(--hollow-button-text-decoration);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--hollow-button-hover-background);
                    border-color: var(--hollow-button-hover-border);
                    border-radius: var(--hollow-button-hover-border-radius);
                    color: var(--hollow-button-hover-color);
                    padding-inline: var(--hollow-button-hover-padding);
                    text-decoration: var(--hollow-button-hover-text-decoration);
                }
            }
        }

        &[disabled] {
            border-color: var(--hollow-button-disabled-border);
        }
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        text-align: start;

        &:hover,
        &:focus {
            text-decoration: underline;
            padding: 0;
        }
    }
}
