@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAttributeValue {
    &-Color {
        border-radius: 0;
    }

    &-Image-Overlay {
        border-radius: 0;
    }

    &-ColorText {
        font-size: $fs-s;
    }

    .Field-CheckboxLabel {
        display: flex;

        data {
            order: -1;
        }
    }

    &-Image {
        border-radius: 0;
    }

    &-Label {
        font-size: $fs-s;
        font-weight: $fw-regular;
        line-height: 20px;
        text-transform: lowercase;
    }
}
