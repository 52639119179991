@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.PromotionalBanner {
    margin: 25px 0;
    min-height: 160px;

    @include flex($align: center, $justify: space-between);

    @include mobileAndTablet {
        padding: 16px;
        min-height: 96px;
    }

    &_variant_image {
        @include mobile {
            display: none !important;
        }
    }

    &-Text {
        color: $color-primary;
        font-weight: $fw-regular;
        font-size: $fs-xl;
        padding-inline-end: 50px;

        @include mobileAndTablet {
            font-size: $fs-m;
            padding-inline-end: 15px;
        }
    }

    &-Button {
        --hollow-button-background: transparent;
        --hollow-button-hover-background: #{$white};

        @include mobileAndTablet {
            min-width: 130px;
        }
    }

    &.header {
        overflow: hidden;

        img {
            @include mobile {
                display: none;
            }
        }
    }

    &.wide,
    &.header {
        max-height: 162px;

        @include flex($justify: space-between, $align: center);

        > div {
            width: 100%;
        }

        .PromotionalBanner-Text {
            padding: 0 0 0 52px;
            max-width: 70%;
            position: absolute;
            height: 100%;
            inset-block-start: 0;
            inset-inline-start: 0;
            z-index: 1;

            @include flex($align: center);
        }

        img {
            width: auto;
            height: auto;
            max-height: 160px;
            max-width: 100%;
            margin-block-start: 1px;
            float: right;

            @include mobile {
                display: none;
            }
        }

        .PromotionalBanner-Button {
            position: absolute;
            inset-inline-end: 15%;
            inset-block-start: 50%;
            transform: translateY(-50%);
        }
    }

    &.header {
        .PromotionalBanner-Button {
            @include mobile {
                display: none;
            }
        }
    }

    &.wide {
        @include mobile {
            padding: 0;
        }

        .PromotionalBanner-Button {
            inset-inline-end: 7%;

            @include mobile {
                width: auto;
                inset-inline-end: 2%;
            }
        }

        .PromotionalBanner-Text {
            @include mobile {
                padding: 0 0 0 15px;

            }
        }
    }

    &.tile {
        @include flex($dir: column, $justify: center);

        margin: 15px;

        .PromotionalBanner {
            &-Text {
                padding: 30px 0;
                max-width: 70%;
                position: absolute;
                height: 100%;
                inset-block-start: -10px;
                inset-inline-start: 35px;
                z-index: 1;
            }

            &-Button {
                margin: 30px 0;
                position: absolute;
                inset-inline-start: 10%;
                inset-block-end: 1%;

                @include mobile {
                    width: calc(100% - 20%);
                }
            }
        }

        @include mobile {
            margin: 0;
            padding: 0;
        }

        @include mobileAndTablet {
            margin-block-end: 25px;
        }
    }
}

.CategoryProductList-Page_layout_grid {
    + .CategoryProductList-Page_layout_grid {
        @include mobile {
            display: none !important;
        }
    }
}

.ProductListPage {
    li { 
        &::before {
            display: none;
        }
    }
}