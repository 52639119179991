.hpProducers {
    display: none;
}

@include tabletAndAbove {
    .hpProducers {
        display: flex;
        flex-wrap: wrap;
        margin: 24px 0;

        &-Wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-block-end: 60px;
        }

        &-Item {
            flex: 0 0 30%;
            text-align: center;
            margin-block-end: 3rem;
        }

        &-SeeAll {
            color: $color-primary;
            font-size: $fs-xs;
            padding-block-end: 3px;
            font-weight: $fw-medium;
            border-bottom: 1px solid $color-primary;
        }

        &-SectionTitle {
            margin: 0;
        }
    }
}

@include desktopAndAbove {
    .hpProducers {
        &-Item {
            flex: 0 0 15%;
            margin-block-end: 0;
        }
    }
}
