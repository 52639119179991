@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SliderCounterNav {
    width: 108px;
    height: 24px;
    margin-block-end: 47px;
    padding: 0 8px;
    border: solid 1px $gray-light;

    @include flex($align: center, $justify: space-between);

    &-Arrow {
        @include flex($align: center, $justify: center);

        cursor: pointer;
    }

    &-Counter {
        font-size: $fs-xxs;
    }
}
