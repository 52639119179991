.HpDescriptions {
    display: flex;
    flex-direction: column;
    margin: 44px 0 89px;

    h2.HpDescriptions-SectionTitle {
        font-weight: $fw-regular;
        font-size: $fs-xxl;
        line-height: 24px;

        @include mobile {
            font-size: $fs-l;
        }
    }

    &-Text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 136px;
        max-width: 1295px;
        margin-block-start: 8px;

        @include mobile {
            grid-template-columns: 1fr;
        }

        p {
            font-size: $fs-s;
            line-height: 24px;
            font-weight: $fw-light;
        }
    }
}
