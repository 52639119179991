.CmsPage {
    @mixin link-button {
        border-bottom: 1px solid $color-primary;
        color: $color-primary;
        display: inline-block;
        font-size: $fs-s;
        font-weight: $fw-medium;
        line-height: 24px;
        outline: 1px solid $color-primary;
        padding: 8px 35px;
        text-align: center;
        transition: background-color 150ms ease;

        &:hover { 
            background: $color-primary-lighter
        }
    }

    section {
        margin-block-end: 56px;

        @include mobile {
            margin-block-end: 32px;
        }
    }

    .section-image-text {
        @include headings {
            font-size: $fs-xxxl;
            line-height: 36px;
            letter-spacing: -0.04em;
            margin-block-end: 32px;

            @include mobile {
                font-size: $fs-l;
                line-height: 24px;
                margin-block-end: 16px;
            }
        }

        .image-wrapper {
            width: 486px;
            margin-inline-end: 80px;

            @include mobile {
                margin-block-end: 24px;
                margin-inline-end: 0;
                width: 100%;
            }
        }

        &-content {
            @include flex($align: center);

            @include mobile {
                flex-direction: column;
            }

            &-text {
                padding: 10px 42px;

                @include mobile {
                    padding: 15px 0;
                }
            }

            p, img {
                flex: 1;
            }

            p {
                font-weight: $fw-light;
                line-height: 24px;
            }
        }
    }

    .section-promo {
        background-color: $color-secondary-lighter;
        padding: 46px 24px;
        text-align: center;

        &-actions {
            margin-block-start: 28px;
        }

        p {
            color: $color-primary;
            font-size: $fs-l;
            line-height: 28px;

            @include mobile {
                font-size: $fs-m;
                line-height: 24px;
            }
        }

        a {
            @include link-button;

            padding: 10px 50px;

            @include mobile {
                width: 70%;
            }
        }
    }

    .section-image-text-list {
        margin: 0 auto;
        max-width: 1100px;

        @include headings {
            letter-spacing: -0.02em;
            line-height: 36px;
            font-size: $fs-xxxl;
            margin-block-end: 70px;
            margin-block-start: 15px;
            text-align: center;

            @include mobile {
                font-size: $fs-xl;
                line-height: 32px;
                margin-block-end: 30px;
                margin-block-start: 10px;
            }
        }

        &-item {
            @include flex($align: center);

            margin-block-end: 87px;

            @include mobile {
                flex-direction: column;
                margin-block-end: 35px;
            }

            &:nth-of-type(2n) {
                flex-direction: row-reverse;

                @include mobile {
                    flex-direction: column;
                }

                .section-image-text-content-text {
                    padding-inline-end: 70px;
                    padding-inline-start: 0px;

                    @include mobile {
                        padding-inline-end: 0px;
                        padding-inline-start: 0px;
                    }
                }
            }

            &:nth-of-type(2n+1) {
                .section-image-text-content-text {
                    padding-inline-end: 0px;
                    padding-inline-start: 70px;

                    @include mobile {
                        padding-inline-end: 0px;
                        padding-inline-start: 0px;
                    }
                }
            }

            @include headings {
                font-size: $fs-xl;
                letter-spacing: -0.03em;
                line-height: 28px;
                margin-block-end: 24px;
                text-align: start;

                @include mobile {
                    margin-block-start: 4px;
                    margin-block-end: 16px
                }
            }

            p { 
                font-weight: $fw-light;
                line-height: 24px;
            }
        
            a {
                @include link-button;

                margin-block-start: 15px;
                padding-block: 10px;

                @include mobile {
                    width: 100%;
                }
            }

            .image-wrapper {
                @include mobile { 
                    width: 100%;
                }

                img { 
                    width: 332px;

                    @include mobile {
                        width: 100%;
                    }
                }
            }
        }
    }
}
