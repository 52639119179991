/* stylelint-disable declaration-no-important */

.o_livechat_button {
    background-color: #E4E5F8 !important;
    background-image: url('../../assets/icons/chat.svg') !important;
    z-index: 999;
    border: 1px solid #10069F;
    border-radius: 0;
    height: 48px;
    width: 48px;
    min-width: 48px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px 25px;
    text-indent: -9999px;

    @include mobile {
        inset-block-end: 92px;
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin-inline-start: 16px;
    }

    &.d-print-none {
        @include mobileAndTablet {
            display: block !important;
        }
    }
}

.o_thread_window {
    $parent: &;

    & &_header {
        height: 35px;
        background: #4a4dce !important;

        @include mobile {
            span:first-child {
                order: 1;
            }
        }

        @include mobile {
            height: 45px;
        }

        #{$parent}_close {
            @include mobile {
                padding-block: 0;
            }

            &::before {
                content: "\00d7" !important;
                font-size: 17px;
                line-height: 17px;
            }
        }

        #{$parent}_title {
            vertical-align: middle;

            &.fa-close {
                font-size: 17px;
                line-height: 17px;

                &::before {
                    font-size: 17px;
                    line-height: 17px;
                }
            }
        }
    }
}
