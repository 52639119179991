@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.GroupedProductsItem {
    max-width: 915px;
    box-shadow: 0 18px 45px rgba(0, 0, 0, 0.08);
    margin: 8px auto;
    min-height: 128px;
    background: $white;

    @include flex($align: stretch);

    &_isHiddenElement {
        box-shadow: none;
        z-index: -1;
    }

    &-Content,
    &-Substitutes {
        flex: 1 1;
        height: auto;
        padding: 14px;
    }

    &-Content {
        display: grid;
        align-items: flex-start;
    }

    &-Substitutes {
        .Field-Wrapper {
            &:first-child .Field {
                margin-block-start: 0;
            }

            .Field {
                margin-block-start: 14px;

                [type='radio']+.input-control {
                    align-self: flex-start;
                    width: 20px;
                    height: 20px;
                    flex: 0 0 20px;
                }
            }
        }
    }

    &-Details {
        width: 100%;
        align-self: flex-end;

        @include flex($wrap: wrap, $align: center);

        div:first-child {
            width: 45%;

            @include mobile {
                width: 55%;
            }
        }

        .ProductActions-Quantity {
            width: 35%;
            flex: 1 1;
            margin-block-end: 0;

            @include mobile {
                width: 45%;
            }
        }

        >div {
            .ProductStockAvailability {
                width: 100%;

                b {
                    @include mobile {
                        display: block;
                    }
                }

                b.ProductStockAvailability-Value_fast {
                    @include mobile {
                        display: inline-block;
                    }
                }
            }
        }
    }

    &-Title {
        flex-wrap: nowrap;
        flex-direction: row;
        width: 100%;
        margin-block-end: 12px;

        @include mobileAndTablet {
            margin-block-end: 14px;
        }

        & p {
            margin-block-end: 0;
        }
    }

    &-Link {
        text-overflow: ellipsis;
        max-width: 90%;
        overflow: hidden;
        white-space: nowrap;
    }

    &-ChangeProduct {
        @include flex($align: center);

        margin-inline-start: 18px;
        cursor: pointer;

        @include desktopAndAbove {
            flex: 0 0 76px;
        }

        span {
            color: $color-primary;
            font-weight: $fw-regular;
            font-size: $fs-xs;
            line-height: 20px;

            @include mobileAndTablet {
                @include visually-hidden;
            }
        }

        svg {
            margin-inline-start: 6px;

            @include desktopAndAbove {
                margin-inline-start: 6px;
            }

            path {
                fill: $color-primary;
            }
        }

        &:disabled {
            cursor: not-allowed;

            span {
                color: $gray4;
            }

            svg {
                path {
                    fill: $gray4;
                }
            }
        }
    }

    &-Image {
        flex: 0 0 64px;
        width: 64px;
        height: 64px;
        padding-block-end: 0;

        @include desktopAndAbove {
            flex: 0 0 128px;
            width: 128px;
            height: 128px;
        }

        @include flex($justify: center, $align: center);

        img {
            max-height: 100px;
            max-width: 100px;
            position: relative;
        }

        &::after {
            display: none;
        }
    }


    .ProductPrice {
        &-PriceValue {
            font-size: $fs-xl;

            @include mobile {
                font-size: $fs-m;
            }
        }

        &_hasDiscount {
            @include flex($dir: column-reverse, $align: flex-end);

            @include mobile {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: baseline;
            }

            del {
                font-size: $fs-s;

                @include mobile {
                    font-size: $fs-xxs;
                }
            }
        }
    }

    .GroupedProductsTooltip {
        position: absolute;
        height: 20px;
        width: 20px;
        inset-block-end: 10px;
        inset-inline-start: 6px;
        z-index: 10;

        &:hover {
            .GroupedProductsTooltip-Popper {
                display: block;
            }
        }

        .ProductActions-Checkbox {
            margin-block-start: 0;

            .input-control {
                width: 20px;
                height: 20px;
            }
        }

        &-Hover {
            span {
                display: block;
                height: 8px;
                width: 8px;
                z-index: 1;
            }
        }

        &-Popper {
            z-index: 10;
            display: none;

            &[data-popper-placement*="bottom"] .GroupedProductsTooltip-Arrow {
                inset-block-start: 0;
                margin-block-start: -8px;

                &::before {
                    border-width: 0 8px 8px;
                    border-color: transparent transparent $white;
                }
            }

            &[data-popper-placement*="top"] .GroupedProductsTooltip-Arrow {
                inset-block-end: 0;
                margin-block-end: -8px;

                &::before {
                    border-width: 8px 8px 0;
                    border-color: $white transparent transparent;
                }
            }
        }

        &-Arrow {
            width: 19px;
            height: 8px;

            &::before {
                content: "";
                margin: auto;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
            }
        }

        &-Content {
            padding-block: 12px;
            padding-inline: 12px;
            width: 120px;
            background: $white;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
            font-size: $fs-xs;
        }

        &-Description {
            overflow: hidden;

            * {
                font-size: $fs-xs;
                font-weight: $fw-light;
                line-height: 20px;
            }
        }

        .Field {
            margin-block-start: 0;

            .input-control {
                width: 20px;
                height: 20px;
            }
        }
    }

    &-Calculator {
        span {
            font-size: $fs-xxs;
        }
    }

    >.Field-Wrapper_type_checkbox {
        position: absolute;
        inset-inline-start: 6px;
        inset-block-end: 6px;

        .input-control {
            width: 20px;
            height: 20px;
        }
    }

    .ProductLabel {
        inset-block-start: 6px;
        inset-inline-start: 6px;
        margin: 0;
    }

    &-OutOfStock {
        width: 35%;
        flex: 1 1;
        margin-block-end: 0;
        text-align: start;

        @include mobile {
            width: 100%;
        }
    }
}
