:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray: #{$gray-light};
    --color-dark-gray: #{$gray};
    --color-karaka: #{$black};
    --primary-divider-color: #e0e0e0;
    --secondary-error-color: #fff;
    --primary-error-color: #{$error};
    --secondary-success-color: #fff;
    --primary-success-color: #{$accept};
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: #{$gold};
    --body-content-color: var(--color-black);

    // Placeholders
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(--imported_primary_base_color, #{$color-primary});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$color-secondary});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
}
