@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --content-wrapper-width: 1440px;
}

.ContentWrapper {
    max-width: var(--content-wrapper-width);
    padding-inline: 32px;
    margin-inline: auto;

    @include mobile {
        padding-inline: 16px;
    }
}
