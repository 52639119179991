@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Consents {
    &-FieldWrapper {
        display: flex;
        flex-direction: column;

        .Field-ErrorMessages {
            margin-block-start: 5px;
            text-align: start;
        }

        &:last-of-type {
            margin-block-end: 15px;
        }
    }

    .Field_type_checkbox {
        &.Consents {
            &-Field {
                .input-control {
                    min-width: 18px;

                    & ~ span {
                        p:last-of-type {
                            margin-block-end: 0;
                        }
                    }
                }

                .Field-Icon {
                    display: none;
                    visibility: hidden;
                    height: 0;
                    width: 0;
                }

                .Field-CheckboxLabel {
                    display: flex;
                    align-items: flex-start;

                    [data-content-type="text"] * {
                        &:last-child {
                            margin-block-end: 0;
                        }
                    }
                }
            }
        }
    }
}
