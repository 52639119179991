@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.BlogWidget {
    margin: 24px -32px 47px;
    padding: 0 32px;
    overflow: hidden;

    @include mobile {
        margin: 16px 0 22px;
        padding: 0;
    }

    .SliderNext,
    .SliderPrev {
        opacity: 0;

        @include mobileAndTablet {
            @include visually-hidden;
        }
    }

    &:hover {
        .SliderNext,
        .SliderPrev {
            opacity: 1;
        }
    }

    &-Head {
        @include flex($wrap: wrap, $align: center, $justify: center);

        margin-block-end: 40px;

        .SliderCounterNav {
            margin-block-end: 0;
        }

        >* {
            &:first-child {
                @include desktop {
                    position: absolute;
                    inset-inline-start: 0;
                }
            }

            &:last-child {
                @include desktop {
                    position: absolute;
                    inset-inline-end: 0;
                }
            }
        }

        @include mobile {
            justify-content: space-between;
        }
    }

    &-Header {
        margin-block-end: 0;
        font-size: 28px;

        @include mobile {
            width: 100%;
            margin-block-end: 28px;
            font-size: 22px;

            @include flex();

            &:not(&_hiddenSwipe) {
                &::after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 22px;
                    background-image: url("src/assets/icons/swipe.svg");
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: transparent;
                    background-size: contain;
                    margin-inline-start: 18px;
                }
            }
        }
    }

    &-ShowAll {
        color: $color-primary;
        font-size: $fs-xs;
        text-decoration: underline;
        font-weight: $fw-medium;
    }

    &-CardWrapper {
        @include mobile {
            height: 100%;
        }
    }

    &-Card {
        @include flex();

        border: solid 1px $gray-light;
        margin-block-end: 15px;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
            box-shadow: 0 111px 70px rgba(0, 0, 0, 0.02),
                0 50px 28px rgba(0, 0, 0, 0.05), 0 27px 13px rgba(0, 0, 0, 0.05),
                0 15px 7px rgba(0, 0, 0, 0.05), 0 8px 3px rgba(0, 0, 0, 0.05),
                0 4px 1px rgba(0, 0, 0, 0.04), 0 1px 0 rgba(0, 0, 0, 0.03);
        }

        @include mobile {
            @include flex($dir: column);

            height: 100%;
        }
    }

    &-ImageWrapper {
        min-width: 333px;
        height: 240px;

        @include mobile {
            min-width: unset;
            width: 100%;
            height: 240px;
        }

        @include narrow-desktop {
            min-width: 222px;
        }

        .Image {
            >img {
                object-fit: cover;
            }
        }
    }

    &-Content {
        padding: 31px;

        @include mobile {
            padding: 20px 20px 32px 16px;
        }

        .BlogWidget {
            &-PostTitle {
                @include mobile {
                    font-size: $fs-m;
                    margin-block-end: 16px;
                }
            }
        }
    }

    &-PostTitle {
        font-size: $fs-m;
        line-height: 24px;
        display: inline-block;
        margin-block-end: 15px;

        &:hover {
            color: $color-primary;
        }

        @include mobile {
            margin-block-end: 10px;
        }
    }

    &-Description p {
        font-size: $fs-xs;
        font-weight: $fw-light;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        overflow: hidden;

        @include desktop {
            line-height: 20px;
            -webkit-line-clamp: 4;
        }
    }

    &-ArrowLink {
        position: absolute;
        inset-block-end: 20px;
        inset-inline-end: 20px;

        @include mobile {
            inset-block-end: 8px;
        }
    }

    .slick-slider {
        max-width: 100%;
    }

    .slick-list {
        margin: 0 -18px;
        overflow: unset;

        @include mobile {
            margin: 0 -7px;
        }
    }

    .slick-track {
        @include mobile {
            display: flex;
        }
    }

    /* stylelint-disable */
    .slick-slide {
        @include mobile {
            width: 294px !important;
            display: flex !important;
            height: auto;
        }
    }

    .slick-slide>div {
        padding: 0 18px;

        @include mobile {
            padding: 0 7px;
        }
    }
}
