@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FieldSelect {
    &_isExpanded {
        .FieldSelect-Select {
            font-size: 16px;
            line-height: 24px;
            color: $gray;
            border-bottom-color: transparent;
        }
    }

    &-Select {
        font-size: 16px;
        line-height: 24px;
        color: $gray;
    }

    &-Option {
        color: $gray;
        padding: 24px 0 24px 18px;
        display: flex;
        align-items: center;
        line-height: $fs-s;

        &_isPlaceholder {
            opacity: 1;
            color: $gray-semi-transparent;
        }
    }
}

.Field_type_select {
    position: relative;

    .FieldSelect-Options_isExpanded {
        z-index: 92;

        @include tabletAndAbove {
            max-height: 288px;

            &::-webkit-scrollbar-thumb,
            &::-webkit-scrollbar {
                display: block;
            }
        }
    }

    .Field-Label {
        line-height: 20px;
        margin-block-end: 6px;
    }

    .Field-Icon {
        display: none;
    }

    .Field-LabelContainer {
        display: block;
        position: absolute;
        z-index: 1;

        label {
            font-size: 12px;
            line-height: 20px;
            color: $gray;
            inset-block-start: -10px;
            inset-inline-start: 16px;

            &::before {
                content: '';
                width: calc(100% + 8px);
                height: 3px;
                position: absolute;
                inset-inline-start: 50%;
                inset-block-start: 50%;
                transform: translate(-50%, calc(-50% - 2px));
                background-color: $white;
                z-index: -1;
            }

            span {
                font-size: 12px;
                color: $gray;
            }
        }
    }
}

.Field_isValid,
.Field_hasError {
    .FieldSelect_isExpanded select {
        border-color: $gray-light;
        border-bottom-color: transparent;
    }
}

.Field-OuterWrapper-InsetLabel {
    .Field-Wrapper_type_select .Field-Label {
        margin: 0;
        position: absolute;
        z-index: 91;
        inset-inline-start: 18px;
        inset-block-start: -8px;
        font-size: 12px;
        line-height: 20px;
        color: $gray;

        &::before {
            content: '';
            width: calc(100% + 8px);
            height: 3px;
            position: absolute;
            inset-inline-start: 50%;
            inset-block-start: 50%;
            transform: translate(-50%, calc(-50% - 2px));
            background-color: $white;
            z-index: -1;
        }
    }
}
