.CartEmpty {
    &-Bottom {
        width: 100%;
        background: $color-primary-lighter;
        padding: 28px 0;
        gap: 16px;

        @include flex($justify: center, $align: center, $dir: column);
    }

    &-PhoneInfo {
        display: inline-block;
        margin: 0;
        font-weight: $fw-light;

        @include mobile {
            font-size: $fs-s;
            font-weight: $fw-regular;
        }
    }

    &-PhoneLink {
        color: $color-primary;
        font-weight: $fw-medium;
        font-size: $fs-s;
        padding-inline-start: 4px;
    }

    &-Info {
        display: inline-block;
        padding: 0 8px;
        font-size: $fs-xxs;
        margin: 0;

        @include mobile {
            display: block;
            padding: 4px 8px;
        }
    }

    &-Aside {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 4px 1px 0px rgba(0, 0, 0, 0.05), 0px 8px 3px 0px rgba(0, 0, 0, 0.05), 0px 15px 7px 0px rgba(0, 0, 0, 0.05), 0px 28px 14px 0px rgba(0, 0, 0, 0.05), 0px 51px 29px 0px rgba(0, 0, 0, 0.06), 0px 111px 80px 0px rgba(0, 0, 0, 0.07);
        padding: 10px 24px 12px 24px;
        max-width: 332px;
    }

    &-AsideWrapper {
        margin: 14px 0;

        img {
            max-height: 20px;
            width: 20px;
        }

        >img {
            max-height: 128px;
            width: auto;
        }

        >a {
            font-size: 20px;
            font-weight: $fw-medium;
            color: $color-primary;
            display: inline-flex;
            gap: 24px;
        }
    }

    &-AsideSubWrapper {
        gap: 24px;

        @include flex();
    }

    &-AsideInfo {
        font-size: $fs-xs;
    }
}
