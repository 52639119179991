@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.CheckoutInpost {
    display: block;
    margin-bottom: 20px;

    &-SelectedParcelLocker {
        margin: 10px 0 20px 0;

        &Address {
            @include flex($dir: column);

            margin: 15px 0 0;

            >span {
                font-size: 13px;

                &:first-of-type {
                    font-weight: 700;
                }
            }
        }
    }

    &-SelectedParcelLockerTitle {
        font-size: 13px;
        font-weight: 500;
        color: $color-primary;
    }
}

.InpostMapPopup {
    .Popup-Content {
        @include above1200 {
            min-width: 1160px;
        }

        @include desktopAndAbove {
            min-width: 980px;
        }

        @include tablet {
            min-width: 728px;
        }

        @include mobile {
            min-width: 320px;
        }
    }

    .Popup-Body {
        height: calc(100% - 50px);
    }

    .easypack-widget .map-widget .leaflet-popup .leaflet-popup-content-wrapper .point-wrapper p {
        color: $white;
    }
}
