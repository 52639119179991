.Mock {
    &::before {
        content: '';
        position: absolute;
        border: $gold 1px solid;
        width: 100%;
        height: 100%;
        inset-inline-start: -1px;
        inset-block-start: -1px;
    }

    &::after {
        content: 'Mock';
        position: absolute;
        width: 30px;
        height: 16px;
        inset-inline-end: -1px;
        inset-block-start: -9px;
        background-color: $gold;
        color: $white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
    }

    &:hover {
        &::before, &::after {
            display: none;
        }
    }
}
