@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-card-variants-width: 90px;
}

.ProductCard {
    border: solid 1px transparent;

    &:hover {
        @include desktop {
            border: solid 1px $gray-light;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
            z-index: 5;

            .ProductCard {
                &-VisibleOnHover {
                    padding-block-start: 10px;
                    inset-inline-start: -1px;
                    inset-inline-end: -1px;
                    width: unset;
                    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
                    border: solid 1px $gray-light;
                    border-top: none;

                    &_withVariants {
                        padding-inline-start: calc(var(--product-card-variants-width) + 15px);
                        inset-inline-start: calc(-1 * var(--product-card-variants-width));
                        z-index: 1;

                        &::before {
                            content: "";
                            width: calc(var(--product-card-variants-width) - 2px);
                            position: absolute;
                            inset-block: 0;
                            inset-inline-start: 0;
                            border-right: solid 1px $gray-light;
                        }
                    }
                }

                &-ProductActions {
                    display: block;
                }

                &-Link_altImage {
                    .ProductCard-PictureHover {
                        opacity: 1;
                    }
                }

                &-Variants {
                    display: block;
                    box-shadow: none;
                    z-index: 0;
                }

                &-ArrangementHoverButton {
                    @include flex($align: center, $justify: center);

                    width: 100%;
                }
            }
        }
    }

    &-ProductNameContainer {
        border-bottom: solid 1px $gray-light;
        margin-block-end: 10px;
    }

    &-Picture {
        padding-block-end: 100%;
    }

    &-LowestPrice {
        font-weight: $fw-regular;
        font-size: $fs-xxxs;
        color: $black;
        margin-block-end: 3px;
        line-height: 16px;
        width: 100%;

        span {
            color: $black;
        }
    }

    &-Variants {
        display: none;
        border: solid 1px $gray-light;
        border-bottom: none;
        position: absolute;
        inset-block: -1px;
        width: var(--product-card-variants-width);
        inset-inline-start: calc(-1 * var(--product-card-variants-width));
        background-color: $white;
        overflow: auto;
        padding: 10px;
    }

    &-Link {
        color: inherit;
        width: 100%;

        @include flex();

        &:hover,
        &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    &-ContentWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-PictureHover {
        opacity: 0;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        z-index: 3;
        transition: opacity 200ms;

        img {
            object-position: 50% calc(50% - 3px);
        }
    }

    &-ProductActions {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 5;

        @include desktop {
            display: none;
        }

        .ProductWishlistButton {
            margin-inline-end: 0;

            svg {
                stroke: $color-primary;
            }
        }

        .ProductCompareButton {
            margin-inline-end: 10px;

            svg {
                fill: $color-primary;
            }
        }
    }

    &-Content {
        padding-block-end: 0;
    }

    &-Name {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 60px;
        padding-block-end: 0;
        text-overflow: ellipsis;
        white-space: normal;
        width: 100%;

        @include mobile {
            margin-block-end: 7px;
        }

        @include desktop {
            line-height: 24px;
            height: 72px;
        }
    }

    &-FigureReview {
        @include desktop {
            padding-block-start: 15px;
        }
    }

    &-DeliveryTime {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: solid 1px $gray-light;
        width: auto;
        align-self: flex-start;
        padding: 4px 8px;
        margin-block-start: 3px;
    }

    &-ConfigurableAttributes {
        @include flex($wrap: wrap);
    }

    &-ConfigurableAttribute {
        border: solid 1px $gray-light;
        font-size: $fs-xxs;
        font-weight: $fw-medium;
        padding: 2px 8px;
    }

    &-Discount {
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 0;
        z-index: 5;
    }

    &-Reviews {
        @include mobile {
            margin-block-end: 6px;
        }

        @include desktop {
            height: 28px;
        }
    }

    &-Footer {
        padding-block-start: 15px;

        >.Button {
            margin-inline-end: 0;
        }
    }

    &_layout_grid {
        @include mobileAndTablet {
            height: auto;
            border: solid 1px $gray-light;
            margin-block-end: 25px;

            &:last-child {
                margin-block-end: 25px;
            }

            .ProductCard {
                &-Content {
                    padding-block: 10px;
                    padding-inline: 10px;
                }

                &-ProductActions {
                    inset-block-start: 10px;
                    inset-inline-end: 10px;
                }
            }
        }
    }

    .ProductPrice {
        &-PriceValue {
            @include mobile {
                font-size: $fs-xs;
            }
        }

        del {
            @include mobile {
                font-size: $fs-xxxs;
            }
        }
    }

    .ProductLabel {
        @include desktop {
            padding: 15px 15px 0;
        }
    }

    &_isNotListElement {
        line-height: 24px;
    }
}
